import React, { PureComponent } from 'react';
import { layer, source, feature, interaction } from 'react-openlayers';
import GeometryType from 'ol/geom/GeometryType';
import { BLACK, WHITE } from '../../../rgbColors';
import { getCurrentMission } from '../../../dataModelHelpers';

import EventBus from '../../../EventBus';

import { MISSION_EVENTS } from '../../../missionEvents';
import { convertProjection4329 } from '../../../utils';
import { Feature, GeometryObject, LineString, Point } from '@turf/helpers';

interface MapMakerScheduleLayerProps {
  scheduleFeatures: Feature<GeometryObject>[];
  showScheduledJobs: boolean;
  showOrderLine: boolean;
  missionActive: string;
  loadMissionMaps: (jobId: string) => Promise<void>;
}

export default class MapMakerScheduleLayer extends PureComponent<MapMakerScheduleLayerProps> {
  FIELD_TRANSPARENCY = '40'; // 25% opacity
  fieldKeys: string[];
  prevMissionLat: null | number;
  prevMissionLon: null | number;

  constructor(props) {
    super(props);

    this.fieldKeys = [];

    this.updateMissionPullin = this.updateMissionPullin.bind(this);

    this.prevMissionLat = null;
    this.prevMissionLon = null;
  }

  componentDidMount() {
    EventBus.on(MISSION_EVENTS.UPDATED, this.updateMissionPullin);
  }

  componentWillUnmount() {
    EventBus.remove(MISSION_EVENTS.UPDATED, this.updateMissionPullin);
  }

  updateMissionPullin() {
    const mission = getCurrentMission();
    if (mission && (mission.pullin_lat !== this.prevMissionLat || mission.pullin_lon !== this.prevMissionLon)) {
      this.forceUpdate();
      this.prevMissionLat = mission.pullin_lat;
      this.prevMissionLon = mission.pullin_lon;
    }
  }

  /**
   * Handle that calls and returns output from gen function based on type.
   * @param {object} scheduleFeature GeoJSON object
   * @returns {PolygonReact | MultiPolygonReact | PointReact} The react component generated
   */
  genScheduleFeature(scheduleFeature: Feature<GeometryObject>, idx: number) {
    const jobID = scheduleFeature.properties?.['Rogo Job ID'];
    const mission = getCurrentMission();

    switch (scheduleFeature.geometry.type) {
      case GeometryType.MULTI_POLYGON:
      case GeometryType.POLYGON:
        if (this.props.showScheduledJobs) {
          return (!mission || mission.job_id !== jobID) && this.genFieldBoundary(scheduleFeature, idx);
        } else {
          return null;
        }
      case GeometryType.LINE_STRING:
        if (this.props.showOrderLine) {
          return this.genOrderLine(scheduleFeature);
        } else {
          return null;
        }
      case GeometryType.POINT:
        if (this.props.showScheduledJobs) {
          if (scheduleFeature.properties?.name === 'pullin') {
            return (!mission || mission.job_id !== jobID) && this.genPullinPoint(scheduleFeature);
          } else {
            return null;
          }
        } else {
          return null;
        }
      default:
        console.log('Unknown geometry type', scheduleFeature);
        return null;
    }
  }

  geometryIs<T extends GeometryObject>(feature: Feature<any>, type: GeometryType): feature is Feature<T> {
    return feature.geometry.type === type;
  }

  /**
   * Generate operator schedule field boundary
   * @param {object} scheduleFeature GeoJSON object
   * @returns {MultiPolygonReact | PolygonReact} The react component generated
   */
  genFieldBoundary(scheduleFeature: Feature<GeometryObject>, idx: number) {
    // TODO This was not working correctly, will revisit later
    //if (!this.geometryIs<MultiPolygon>(scheduleFeature, GeometryType.MULTI_POLYGON) || !this.geometryIs<Polygon>(scheduleFeature, GeometryType.POLYGON)) return;
    const FieldFeature =
      (scheduleFeature.geometry as any).type === GeometryType.MULTI_POLYGON
        ? feature.MultiPolygonReact
        : feature.PolygonReact;

    const fieldColor = scheduleFeature.properties?.['MapMade'] ? '#43a047' : '#cc0000';
    const fieldKey = `${scheduleFeature.properties?.['Field from Form']}-Field-${scheduleFeature.properties?.['Rogo Job ID']}`;
    const showName = !this.fieldKeys.includes(fieldKey);

    if (showName) {
      this.fieldKeys.push(fieldKey);
    }

    return (
      <FieldFeature
        coordinates={(scheduleFeature.geometry as any).coordinates}
        strokeOptions={{ color: fieldColor, width: 4 }}
        fillOptions={{ color: `${fieldColor}${this.FIELD_TRANSPARENCY}` }}
        textOptions={
          showName
            ? {
                text: scheduleFeature.properties?.['Field from Form'],
                font: '14px Calibri,sans-serif',
                overflow: true,
                strokeOptions: { color: BLACK, width: 2 },
                fillOptions: { color: WHITE },
              }
            : {}
        }
        hideTextZoom={12}
        key={`${fieldKey}-${idx}`}
        properties={scheduleFeature.properties ?? {}}
      />
    );
  }
  /**
   * Generate the pullin point for the operator schedule field
   * @param {object} scheduleFeature GeoJSON object
   * @returns {PointReact} The react component generated
   */
  genPullinPoint(scheduleFeature: Feature<GeometryObject>) {
    if (!this.geometryIs<Point>(scheduleFeature, GeometryType.POINT)) {
      return;
    }
    
    const pullinColor = scheduleFeature.properties?.['MapMade'] ? '#43a047' : '#cc0000';
    const showName = scheduleFeature.properties?.['noBoundary'];

    return (
      <feature.PointReact
        coordinate={scheduleFeature.geometry.coordinates}
        textOptions={{
          text: showName ? scheduleFeature.properties?.['Field from Form'] : '',
          font: '14px Calibri,sans-serif',
          overflow: true,
          strokeOptions: { color: BLACK, width: 2 },
          fillOptions: { color: WHITE },
        }}
        circleOptions={{ radius: 6, fillOptions: { color: pullinColor }, strokeOptions: { width: 1, color: WHITE } }}
        key={`${scheduleFeature.properties?.['Field from Form']}-Pullin-${scheduleFeature.properties?.['Rogo Job ID']}`}
      />
    );
  }

  genOrderLine(scheduleFeature: Feature<GeometryObject>) {
    if (!this.geometryIs<LineString>(scheduleFeature, GeometryType.LINE_STRING)){
      return;
    }

    const mission = getCurrentMission();
    if (mission) {
      scheduleFeature.geometry.coordinates[scheduleFeature.properties?.['loadedMissionIdx']] = convertProjection4329([
        mission.pullin_lon,
        mission.pullin_lat,
      ]);
    }

    return (
      <feature.LineStringReact
        key={scheduleFeature.geometry.coordinates.slice(0, 2).join('-')}
        coordinates={scheduleFeature.geometry.coordinates}
        strokeOptions={{ color: scheduleFeature.properties?.['complete'] ? '#43a047B2' : '#cc0000B2', width: 4 }}
      />
    );
  }

  /**
   * Select handle that allows user to select a field to load from the map
   * @param {*} event
   */
  handleSelectFeature(feature) {
    console.log('feat', feature);
    if (feature) {
      const properties = feature.getProperties();
      if (properties.name === 'field') {
        this.props.loadMissionMaps(properties['Rogo Job ID']);
      }
    }

    return false;
  }

  render() {
    this.fieldKeys = [];
    return (
      // @ts-ignore
      <layer.Vector zIndex={1}>
        {/* 
                // @ts-ignore */}
        <source.VectorSourceReact>
          {this.props.scheduleFeatures.map((feature, idx) => this.genScheduleFeature(feature, idx))}
          {!Boolean(this.props.missionActive) && (
            <interaction.SelectReact filter={this.handleSelectFeature.bind(this)} style={null} />
          )}
        </source.VectorSourceReact>
      </layer.Vector>
    );
  }
}
