import React, { PureComponent } from 'react';

import CommonMap from './CommonMap';

import GridLayer, { GridLayerFeature } from './layers/GridLayer';

import { MAP_TYPES } from './helpers/mapTypes';
import { Point, Polygon } from 'ol/geom';
import { OnLoading, SettingsAccessLevel } from '../../types/types';
import LayerCheckContainer from './buttons/LayerCheckContainer';
import LayerCustomCheckBox from './buttons/LayerCustomCheck';
import MapMakerScheduleLayer from './layers/MapMakerScheduleLayer';
import { GeometryObject, Feature as TurfFeature } from '@turf/helpers';
import MapTextOnlyButton from './buttons/MapTextOnlyButton';
import { getCurrentMission } from '../../dataModelHelpers';
import EventBus from '../../EventBus';
import { MISSION_EVENTS } from '../../missionEvents';
import Feature from 'ol/Feature';
import { ZoneType } from '../../db';
import Airtable from '../../airtable';
import { getArea } from 'ol/sphere';
import { convertProjection4329, isLocalhost, round } from '../../utils';
import { Typography } from '@material-ui/core';
import { KM_PER_MILE, SQ_M_PER_ACRE } from '../../constants';
import ScheduleFieldZoomButton from './buttons/ScheduleFieldZoomButton';
import { dispatchCoresNeedUpdate, MAP_EVENTS } from '../../mapEvents';
import { MapShowPulledCoreLocation } from '../../db/local_storage';
import MapButton from './buttons/MapButton';
import shp from 'shpjs';
import { GenericPointsLayer } from './layers/GenericPointsLayer';
import { feature } from 'react-openlayers';
import { alertWarn } from '../../alertDispatcher';
import { TbAnalyzeFilled } from 'react-icons/tb';
import { Coordinate } from 'ol/coordinate';

interface MapMakerMapProps {
  accessLevel: SettingsAccessLevel;
  missionActive: string;
  scheduleFeatures: TurfFeature<GeometryObject>[];
  loadMissionMaps: (jobId: string) => Promise<void>;
  onLoading: OnLoading;
  openExitInterview: () => void;
}

interface MapMakerMapState {
  gridLayerFeatures: GridLayerFeature[];
  showOrderLine: boolean;
  showScheduledJobs: boolean;
  fields: Feature<Polygon>[];
  density: number;
  acres: number;
  uploadedFeatures: Feature<Point>[];
  showPulledCoreLocation: boolean;
}

export default class MapMakerMap extends PureComponent<MapMakerMapProps, MapMakerMapState> {
  constructor(props: MapMakerMapProps) {
    super(props);

    this.state = {
      gridLayerFeatures: [],
      showOrderLine: true,
      showScheduledJobs: true,
      fields: [],
      density: 0,
      acres: 0,
      uploadedFeatures: [],
      showPulledCoreLocation: MapShowPulledCoreLocation.get(),
    };
  }

  async componentDidMount() {
    EventBus.on(MISSION_EVENTS.UPDATED, this.initFields);

    await this.initFields();
  }

  componentWillUnmount() {
    EventBus.remove(MISSION_EVENTS.UPDATED, this.initFields);
  }

  initFields = async () => {
    const mission = getCurrentMission();
    if (!mission) return;
    const fieldZone = mission.getZones().find((sel) => sel.zone_type === ZoneType.FIELD);
    if (!fieldZone) return;
    const features = await fieldZone.to_feature();
    const fields = features.map((feature) => {
      const coords = feature.geometry.coordinates.map((coords) =>
        coords.map((ring) => {
          return ring;
        }),
      );
      const geom = new Polygon(coords);
      return new Feature(geom);
    });
    await this.getDensity();
    this.setState({ fields });
  };

  async getDensity() {
    const currentMission = getCurrentMission();
    try {
      if (!currentMission?.job_id) {
        console.warn('No job_id set for current mission');
        return;
      }
      const record = await Airtable.getRecord('Jobs', currentMission.job_id);
      if (record && record.get('Deal Density')) {
        this.setState({ density: record.get('Deal Density') as number });
      } else {
        console.warn('No Deal Density set for job');
      }
    } catch (err) {
      console.error('Could not pull airtable record for deal density', err);
    }
  }

  getAcres() {
    let acres = 0.0;
    for (let f of this.state.fields) {
      const geom = f.getGeometry();
      if (geom) {
        acres += getArea(geom) / SQ_M_PER_ACRE; // m^2 to acres
      }
    }
    return round(acres, 2);
  }

  render() {
    const currentMission = getCurrentMission();
    const sitesType = currentMission?.getJob()?.sites_type;
    const isGrid = sitesType?.toLowerCase() !== 'zone';
    const targetSamples = Math.ceil(this.getAcres() / this.state.density);
    const actualSamples = currentMission?.getSampleSites().length;

    return (
      <CommonMap
        type={MAP_TYPES.MAPS}
        buttons={[
          <ScheduleFieldZoomButton
            key={'ScheduleFieldZoomButton'}
            updateCenter={(center) => {
              EventBus.dispatch(MAP_EVENTS.SET_CENTER, center);
            }}
            updateFit={(fit) => {
              EventBus.dispatch(MAP_EVENTS.SET_FIT, fit);
            }}
            // @ts-ignore
            scheduleFeatures={this.props.scheduleFeatures}
          />,

          <MapButton
            key={'ShowPulledCoreLocation'}
            onClick={() =>
              this.setState((prevState) => {
                const newValue = !prevState.showPulledCoreLocation;
                MapShowPulledCoreLocation.set(newValue);
                dispatchCoresNeedUpdate();

                return { showPulledCoreLocation: newValue };
              })
            }
            tooltip={`${this.state.showPulledCoreLocation ? 'Disable' : 'Enable'} showing pulled core location`}
            toggled={this.state.showPulledCoreLocation}
          >
            <TbAnalyzeFilled size={22} />
          </MapButton>,
          // Math.ceil(this.state.acres / this.state.density)
          Boolean(this.props.missionActive) && !!sitesType && (
            <MapTextOnlyButton key="sitesTypeButton" text={sitesType} />
          ),
          Boolean(this.props.missionActive) && isGrid && (
            <MapTextOnlyButton key="targetSamplesButton" text={`Target: ${targetSamples}`} />
          ),
          Boolean(this.props.missionActive) && isGrid && (
            <MapTextOnlyButton key="actualSamplesButton" text={`Actual: ${actualSamples}`} />
          ),
          Boolean(this.props.missionActive) && !!currentMission?.path_distance && !isGrid && (
            <MapTextOnlyButton
              key="pathButton"
              text={`Path: ${Math.round(currentMission.path_distance / 100 / KM_PER_MILE) / 10} mi.`}
            />
          ),
          Boolean(this.props.missionActive) && (
            <MapButton key="closeMissionButton" onClick={this.props.openExitInterview}>
              Close Mission
            </MapButton>
          ),
          <LayerCheckContainer key="layerCheckContainerButton" customClassName="map-maker-layer-check">
            <LayerCustomCheckBox
              label={'Order Line'}
              onChange={(e) => {
                this.setState({ showOrderLine: e.target.checked });
              }}
              checked={this.state.showOrderLine}
            />
            <LayerCustomCheckBox
              label={'Scheduled Jobs'}
              checked={this.state.showScheduledJobs}
              onChange={(e) => {
                this.setState({ showScheduledJobs: e.target.checked });
              }}
            />
            {Boolean(this.props.missionActive) && (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography style={{ marginLeft: 10 }} variant={'caption'}>
                  {'Pull-in: '}
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${getCurrentMission()?.pullin_lat.toFixed(
                      6,
                    )}%2C${getCurrentMission()?.pullin_lon.toFixed(6)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`${getCurrentMission()?.pullin_lat.toFixed(6)},${getCurrentMission()?.pullin_lon.toFixed(6)}`}
                  </a>
                </Typography>
              </div>
            )}

            {isLocalhost && Boolean(this.props.missionActive) && (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography style={{ marginLeft: 10 }} variant={'caption'}>
                  <a
                    href={`https://airtable.com/appCnwyyyXRBezmvB/tblXij9xpNwUCLRIM/viwXdnR9WzAOPGVNC/${
                      getCurrentMission()?.job_id
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {'Open on AirTable'}
                  </a>
                </Typography>
              </div>
            )}

            {/* <Typography style={{ marginLeft: 10 }} variant={'caption'}>
                                {`Pull-in: ${getCurrentMission()?.pullin_lat.toFixed(6)},${getCurrentMission()?.pullin_lon.toFixed(6)}`}
                            </Typography> */}
          </LayerCheckContainer>,
          this.props.accessLevel === 'Technician' && (
            <MapButton
              key={'UploadShapefileButton'}
              onClick={() => {}}
              processFileInput={async (data: string | ArrayBuffer) => {
                // asume arraybuffer and .zip file with shapefiles for now
                const geoData = await shp(data);
                const uploadedFeatures = Array.isArray(geoData)
                  ? geoData.map((data) => data.features).flat()
                  : geoData.features;

                if (uploadedFeatures.length > 1000) {
                  alertWarn('Too many features to upload. Please limit to 1000');
                  return;
                }

                // @ts-ignore
                const pointFeatures: Feature<Point>[] = uploadedFeatures.filter((f) => f.geometry.type === 'Point');
                if (pointFeatures.length < uploadedFeatures.length) {
                  alertWarn(
                    `Only point features are supported for now, ${pointFeatures.length} out of ${uploadedFeatures.length} features are points`,
                  );
                  return;
                }

                this.setState({ uploadedFeatures: pointFeatures });
              }}
            >
              BETA Upload Shapefile
            </MapButton>
          ),
          this.props.accessLevel === 'Technician' && (
            <MapButton
              key={'ClearShapefileButton'}
              onClick={() => {
                this.setState({ uploadedFeatures: [] });
              }}
            >
              BETA Clear Extra Shapedata
            </MapButton>
          ),
        ]}
        layers={[
          Boolean(this.props.missionActive) && (
            <GridLayer key="gridLayer" gridLayerFeatures={this.state.gridLayerFeatures} />
          ),
          <MapMakerScheduleLayer
            key="scheduleLayer"
            scheduleFeatures={this.props.scheduleFeatures}
            loadMissionMaps={this.props.loadMissionMaps}
            showOrderLine={this.state.showOrderLine}
            showScheduledJobs={this.state.showScheduledJobs}
            missionActive={this.props.missionActive}
          />,
          <GenericPointsLayer
            key="genericPointsLayer"
            points={this.state.uploadedFeatures.map((f, idx) => {
              const coordinate: Coordinate = convertProjection4329([
                // @ts-ignore
                f.geometry.coordinates[0],
                // @ts-ignore
                f.geometry.coordinates[1],
              ]);

              return (
                <feature.PointReact
                  coordinate={coordinate}
                  circleOptions={{
                    radius: 6,
                    fillOptions: { color: 'red' },
                    strokeOptions: { width: 1, color: 'white' },
                  }}
                  key={`${idx}_${coordinate[0]}_${coordinate[1]}`}
                  zIndex={100}
                />
              );
            })}
          />,
        ]}
        accessLevel={this.props.accessLevel}
        onLoading={this.props.onLoading}
        missionActive={this.props.missionActive}
      />
    );
  }
}
