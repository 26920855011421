export const MILES_PER_KM = 0.621371;
export const FEET_PER_MILE = 5280;
export const FEET_PER_METER = 3.28084;
export const METERS_PER_FOOT = 1 / FEET_PER_METER;
export const FEET_PER_KM = FEET_PER_MILE * MILES_PER_KM;
export const MM_PER_INCH = 25.4;
export const MPS_TO_MPH = 2.23694;
export const NM_TO_MILES = 1.15078;
export const MILES_TO_NM = 1 / NM_TO_MILES;

export const SQ_M_PER_ACRE = 4046.872;

export const REORDER_TOLERANCE_METERS = 40;

export const EPOCH = 1609459200; // number of seconds from the Unix Epoch to 1/1/2021 00:00:00 GMT

// the letter 'r' is not included in the character set so we can easily
// differentiate box IDs from Airtable record IDs (all start with 'rec')
// this is used for a base32 encoding so we need 2^5 = 32 characters
// with upper case, L is not a problem, but I is excluded to avoid confusion with 1
// in order to get to 32, we also remove B to avoid confusion with 8
export const BOX_ID_CHARSET = '0123456789ACDEFGHJKLMNPQSTUVWXYZ';
// original 0123456789abcdefghijklmnopqstuvw';

export const MS_PER_S = 1000;
export const MS_PER_MIN = 60 * MS_PER_S;
export const MS_PER_HOUR = 60 * MS_PER_MIN;

export const KM_PER_MILE = 1.609344;

export const ROBOT_IP = '10.0.0.1';

// time constants
export const _MS_PER_SEC = 1000;
export const _MS_PER_MIN = _MS_PER_SEC * 60;
export const _MS_PER_HOUR = _MS_PER_MIN * 60;
export const _MS_PER_DAY = _MS_PER_HOUR * 24;
export const _MS_PER_WEEK = _MS_PER_DAY * 7;

export const CREATE_NEW_POLYGON = -1;

export const M_PER_FT = 0.3048;
export const MM_PER_FT = M_PER_FT * 1000;

export const SAMPLE_SELECTION_TIMEOUT_MS = 2 * _MS_PER_MIN;

export const TARGET_DEPTH_CM_DEFAULT = 30;

export enum MissionState {
  _0_Received_No_Files = '0. Received No Files',
  _1_Received_Partial = '1. Received Partial',
  _2_Ready_2_Make = '2. Ready-2-Make',
  _2X_Mission_Creation_Error = '2X. Mission Creation Error',
  _3_Mission_Auto_Made = '3. Mission Auto-Made',
  _3B_Partially_Made = '3B. Partially Made',
  _4_Mission_Finalized = '4. Mission Finalized',
}

export enum COLOR {
  'GREEN' = 0x00ff00,
  'RED' = 0xff0000,
  'WHITE' = 0xfaf9f6, // soil NOT in bucket, dump NOT required
  'PURPLE' = 0xa020f0,
  'BLUE' = 0x0000ff,
  'YELLOW' = 0xffff00, // dump required
  'ORBRWN' = 0xbe6400, // soil in bucket
  'DARK_YELLOW' = 0x1565c0, // unsampled core for kml layer
  'ORANGE' = 0xffa500,
}

export const SAMPLE_WARNING_DISTANCE_FT = 50;
export const SAMPLE_WARNING_DISTANCE_M = ft_to_m(SAMPLE_WARNING_DISTANCE_FT); // meters
export const CORE_MINIMUM_DISTANCE_RADIUS_M = ft_to_m(15); // meters
export const CORE_DEADBAND_WIDTH_M = ft_to_m(15); // meters

export const DEFAULT_CORE_PRESENT_DISTANCE_FEET = 15;
export const DEFAULT_CORE_PRESENT_DISTANCE_METERS = ft_to_m(DEFAULT_CORE_PRESENT_DISTANCE_FEET); // meters

export const DEFAULT_CORE_CLOSE_DISTANCE_FEET = 50;
export const DEFAULT_CORE_CLOSE_DISTANCE_METERS = ft_to_m(DEFAULT_CORE_CLOSE_DISTANCE_FEET); // meters

export const CORE_MINIMUM_DISTANCE_LEAVING_METERS = ft_to_m(DEFAULT_CORE_PRESENT_DISTANCE_FEET); // meters

export const SAMPLING_TASK = {
  CLOSE_SAMPLE_SELECTION_POPUP: 'CLOSE_SAMPLE_SELECTION_POPUP',
} as const;

export enum FieldParams {
  FieldAlignedSamplePath = 1 << 0,
  NoBoundaryTolerance = 1 << 1,
  ImplementCenteredNavigation = 1 << 2,
}

export const BD_CORE_COMMAND = 3;

export const FERTILITY_CORE_AUTO = 0;
export const FERTILITY_CORE_MANUAL = 1;
export const BD_CORE_MANUAL = 2;
export const BD_CORE_AUTO = 3;

// remaining

export const MINIMUM_CORES = 3;

export function ft_to_m(feet: number) {
  return feet * 0.3048; // 0.3048 meters per foot
}

export const COMPANIES_CUSTOM_POINTS_ID_REGEX = {
  'andes ag':
    'centroid:' +
    '^(\\d+)-([A-Za-z]+)-([A-Za-z0-9]+)-([A-Za-z]+)-(\\d+)-([A-Za-z]+)$:' +
    '^\\d+-[A-Za-z]+-[A-Za-z0-9]+-([A-Za-z]+)-(\\d+)-[A-Za-z]+$' +
    ':$1$2',
};

export const FEET_BETWEEN_CORE_LINES_FOR_MULTI_LAB = 3;
export const METERS_BETWEEN_CORE_LINES_FOR_MULTI_LAB = ft_to_m(FEET_BETWEEN_CORE_LINES_FOR_MULTI_LAB);

export const MINIMUM_COORDINATES_FOR_VALID_BOUNDARY = 4;

export const MIN_LAB_COUNT = 1;

export const MIDPOINT_DUMP_MIN_INCHES = 120;

export const CORE_STOP_MAX_DISTANCE_FT = 2;
