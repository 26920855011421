import {
  Button,
  Dialog,
  DialogActions,
  Grid,
  DialogTitle,
  Typography,
  DialogContent,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TextField,
  Input,
  Tooltip,
  Table,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useState } from 'react';
import {
  BigFieldMode,
  BlockCoringInSampleDeadband,
  BlockCoringLogicEnabled,
  BlockCoringMissingBarcode,
  BlockCoringMissingEntranceInterview,
  BlockCoringSoilMixRisk,
  CoreCountEnforcementEnabled,
  DBPeriodicSync,
  DistanceAheadMetersStorage,
  EnableExperimentalUPSManifestLogo,
  MapCalculationPositionStorage,
  MapDebugArmPosition,
  MapDebugCloseCoreTolerance,
  MapDebugCloseTolerance,
  MapDebugEdgeUnpulledCores,
  MapDebugCoreLabels,
  MapDebugNoSampleCoreTolerance,
  MapDebugNoSampleTolerance,
  MapDebugPresentCoreTolerance,
  MapDebugPresentTolerance,
  MapDebugStorage,
  MapManualDriveAidStorage,
  MapNearbyAutoZoomEnabled,
  OriginPositionOptions,
  RobotArmOffsetX,
  RobotArmOffsetY,
  ScannerPauseDelayMs,
  SimulatorAccelerationFactor,
  SimulatorAddJitter,
  SimulatorEnabledStorage,
  SimulatorFPS,
  SimulatorMaxMilesPerHour,
  MaxZoom,
  MapDebugZoomLevel,
  MapDebugCoreWaypointNumbers,
  CoreMatchingUsingRobotInAutoMode,
  MapDebugEventBus,
  FastMode,
} from '../db/local_storage';
import { LocalStorageDefinition } from '../utils';
import { dispatchMissionUpdated } from '../missionEvents';
import { TargetOrderPreferences, TargetOrderPreferenceStorage } from '../services/TargetCoordinateService';
import { PrinterProtocols, PrintServerProtocol, RJ4230BName } from './robot/configs/PrinterConfig';

export interface MenuDrawerFeatureTableProps {
  showFeatureTable: boolean;
  setShowFeatureTable: (show: boolean) => void;
}

type FeatureTableEntry<T = any> = [
  string,
  string,
  LocalStorageDefinition<T>, // storage definition
  boolean | (() => boolean), // visible
  readonly T[] | undefined, // options
];
const DebugMenuRows: FeatureTableEntry[] = [
  [
    'Big Field Mode',
    'This is useful for large maps. This causes recovery files to only save every 5 minutes, and forcibly throttles the map updates to 1hz (assuming robot is currently sending 10hz)',
    BigFieldMode,
    true,
    undefined,
  ],
  [
    'Coring Block Logic Enabled',
    'This will prevent coring if the data state is not correct (e.g. missing barcode, soil mix risk, etc)',
    BlockCoringLogicEnabled,
    true,
    undefined,
  ],
  ['DB Periodic Sync Mode', 'This will enable the periodic sync of the database', DBPeriodicSync, true, undefined],
  [
    'Coring Block Logic: Entrance Interview Missing',
    'This will prevent coring if the entrance interview is missing',
    BlockCoringMissingEntranceInterview,
    true,
    undefined,
  ],
  [
    'Coring Block Logic: Soil Mix Risk',
    'WARNING: This feature may be buggy and need work, especially as it comes to test cores. This will block coring if we are at risk of mixing soil between samples or between test cores and samples',
    BlockCoringSoilMixRisk,
    true,
    undefined,
  ],
  [
    'Coring Block Logic: Missing Barcode',
    'This will prevent coring if the barcode is missing from a previously selected sample.',
    BlockCoringMissingBarcode,
    true,
    undefined,
  ],
  [
    'Coring Block Logic: In Sample Deadband',
    'This will prevent coring if we are within a certain distance of a sample but not close enough to be present (also works for zone missions)',
    BlockCoringInSampleDeadband,
    true,
    undefined,
  ],
  [
    'Experimental: Enable UPS Manifest Logo',
    'This will enable the UPS logo on the manifest to distinguish between UPS and FedEx.',
    EnableExperimentalUPSManifestLogo,
    true,
    undefined,
  ],
  [
    'Scanner Delay',
    'The number of milliseconds to wait before accepting another barcode scan. Default is 1000ms',
    ScannerPauseDelayMs,
    true,
    undefined,
  ],
  [
    'Distance Ahead',
    'Calculates the distance ahead of the robot that we want to target the map center. Will be capped based on realistic map view',
    DistanceAheadMetersStorage,
    true,
    undefined,
  ],
  ['Enable Core Enforcement', '', CoreCountEnforcementEnabled, true, undefined],
  ['Use Robot Sequence Number For Core Matching in Auto-Mode', '', CoreMatchingUsingRobotInAutoMode, true, undefined],
  ["Map Position Center ('Robot Arm' or 'Arm')", '', MapCalculationPositionStorage, true, OriginPositionOptions],
  [
    'Present Sample Logic Order',
    'This is the order of the logic for determining if we are present at a sample',
    TargetOrderPreferenceStorage,
    true,
    TargetOrderPreferences,
  ],
  [
    'Enable Manual Drive Aid',
    "Enable the 'Sample Distance Indicator' and 'Steering' indicators on the map",
    MapManualDriveAidStorage,
    true,
    undefined,
  ],
  ['Map: Maximum Zoom', 'The maximum zoom level for the map', MaxZoom, true, undefined],
  [
    'Map: Nearby Auto Zoom Enabled',
    'Enable auto zoom when nearby the waypoint sample',
    MapNearbyAutoZoomEnabled,
    true,
    undefined,
  ],
  [
    'Map Debug: Enabled',
    'Sets the debug flag for the map, which will show additional information on the map',
    MapDebugStorage,
    true,
    undefined,
  ],
  ['Map Debug: Event Bus', 'Adds Event Bus Hz and Robot Connection Hz info', MapDebugEventBus, true, undefined],
  [
    'Map Debug: Arm Position',
    'Show the arm position on the map in relation to the robot position',
    MapDebugArmPosition,
    true,
    undefined,
  ],
  ['Map Debug: Close Tolerance', '', MapDebugCloseTolerance, true, undefined],
  ['Map Debug: Present Tolerance', '', MapDebugPresentTolerance, true, undefined],
  ['Map Debug: No Sample Tolerance', '', MapDebugNoSampleTolerance, true, undefined],
  ['Map Debug: Close Core Tolerance', '', MapDebugCloseCoreTolerance, true, undefined],
  ['Map Debug: Present Core Tolerance', '', MapDebugPresentCoreTolerance, true, undefined],
  ['Map Debug: No Sample Core Tolerance', '', MapDebugNoSampleCoreTolerance, true, undefined],
  ['Map Debug: Only show edge unpulled cores', '', MapDebugEdgeUnpulledCores, true, undefined],
  [
    'Map Debug: Show Core Labels',
    'Show the core labels in all missions (not just zone missions)',
    MapDebugCoreLabels,
    true,
    undefined,
  ],
  [
    'Map Debug: Show Core Waypoint Numbers',
    'Show the core waypoint numbers (only works if "Show Core Labels" is on)',
    MapDebugCoreWaypointNumbers,
    true,
    undefined,
  ],
  [
    'Map Debug: Show Zoom Level',
    'Show the current map zoom level as text on the map',
    MapDebugZoomLevel,
    true,
    undefined,
  ],
  [
    'Simulator: Enable Simulator',
    `Enable map simulator for debugging. Need to refresh app after setting
    <br>Shift+H to go home
    <br>Shift+Arrows drive
    <br>Ctrl+Shift+Arrows fast drive
    <br>Shift+C core
    <br>Shift+D dump
    <br>Shift+U barcode`,
    SimulatorEnabledStorage,
    true,
    undefined,
  ],
  [
    'Simulator: Jitter Value',
    'Sets a jitter value for the simulator to more closely match the real world conditions',
    SimulatorAddJitter,
    SimulatorEnabledStorage.get,
    undefined,
  ],
  [
    'Simulator: FPS',
    'Set the frames per second for the simulator. Need to refresh app after setting',
    SimulatorFPS,
    SimulatorEnabledStorage.get,
    undefined,
  ],
  [
    'Simulator: Max Miles Per Hour',
    'Set the max speed for the simulator. Need to refresh app after setting',
    SimulatorMaxMilesPerHour,
    SimulatorEnabledStorage.get,
    undefined,
  ],
  [
    'Simulator Acceleration Factor',
    'This is a general factor for how long it will take the simulator to get up to full speed',
    SimulatorAccelerationFactor,
    SimulatorEnabledStorage.get,
    undefined,
  ],
  ['Robot Arm Offset X', 'The offset of the robot arm in the x direction', RobotArmOffsetX, true, undefined],
  ['Robot Arm Offset Y', 'The offset of the robot arm in the y direction', RobotArmOffsetY, true, undefined],
  [
    'Print Server Protocol',
    'The protocol to use for sending print jobs to the printer',
    PrintServerProtocol,
    true,
    PrinterProtocols,
  ],
  ['Printer Name', 'The name of the printer to use for printing', RJ4230BName, true, undefined],
  [
    'NO FIELD: Fast Mode',
    'Fast Maps Mode: Changes the strictness of the indexeddb syncing, might add additional options',
    FastMode,
    true,
    undefined,
  ],
] as const;

export function MenuDrawerFeatureTable(props: MenuDrawerFeatureTableProps) {
  const [renderCount, setRenderCount] = useState(0);

  return (
    <>
      <Button
        style={{
          color: 'rgba(255, 255, 0, 1)',
        }}
        onClick={() => {
          props.setShowFeatureTable(true);
        }}
      >
        Feature Table
      </Button>
      <Dialog open={props.showFeatureTable} maxWidth={'lg'} fullWidth>
        <DialogActions>
          <Grid container>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <Button
                type="button"
                onClick={() => {
                  props.setShowFeatureTable(false);
                }}
              >
                close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        <DialogTitle>
          <Typography variant="h5" align="center">
            CHANGE AT YOUR OWN RISK
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Key</TableCell>
                  <TableCell align="right">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{generateTableRows()}</TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );

  function generateTableRows() {
    const out: JSX.Element[] = [];

    DebugMenuRows.forEach(([title, tooltip, row, visible, options]) => {
      if (typeof visible === 'function') {
        visible = visible();
      }

      if (!visible) {
        return;
      }

      const value = row.get();
      let editor: JSX.Element | undefined = undefined;
      if (options) {
        editor = (
          <Select
            value={value}
            onChange={(e) => {
              row.set(e.target.value as any);
              setRenderCount(renderCount + 1);
              dispatchMissionUpdated();
            }}
          >
            {options.map((option) => {
              return (
                <MenuItem value={option} key={option}>
                  {option}
                </MenuItem>
              );
            })}
          </Select>
        );
      } else if (typeof value === 'boolean') {
        editor = (
          <Checkbox
            onChange={(e) => {
              row.set(e.target.checked);
              setRenderCount(renderCount + 1);
              dispatchMissionUpdated();
            }}
            checked={row.get()}
          />
        );
      } else if (typeof value === 'number') {
        editor = (
          <TextField
            type="number"
            value={value}
            variant="outlined"
            inputProps={{
              maxLength: 13,
              step: '1',
            }}
            onChange={(e) => {
              row.set(e.target.value);
              setRenderCount(renderCount + 1);
            }}
          />
        );
      } else {
        editor = (
          <Input
            value={row.get()}
            onChange={(e) => {
              row.set(e.target.value);
              setRenderCount(renderCount + 1);
            }}
          />
        );
      }

      const key = title || row.key;

      out.push(
        <Tooltip key={key} title={<Typography variant={'h6'} dangerouslySetInnerHTML={{ __html: tooltip }} />}>
          <TableRow>
            <TableCell component="th" scope="row">
              {key}
            </TableCell>
            <TableCell align="right">{editor}</TableCell>
          </TableRow>
        </Tooltip>,
      );
    });

    return out;
  }
}
