import React, { PureComponent } from 'react';
import { layer, source, feature, interaction } from 'react-openlayers';
import {
  getFeatureType,
  getCollectedFieldKey,
  getFieldKey,
  getPullinKey,
  getPathKey,
  getZoneKey,
  getCorePointKey,
  getSelectedFeature,
  getCentroidKey,
  getKey,
  transformObjectOl,
} from '../helpers/features';
import { KML_FEATURE_TYPE, KmlFeatureType } from '../../../featureTypes';
import { LIGHT_BLUE, BLACK, WHITE, GREEN, BROWN, RED, YELLOW } from '../../../rgbColors';
import { doubleClick } from 'ol/events/condition';
import Collection from 'ol/Collection';
import GeometryType from 'ol/geom/GeometryType';
import EventBus from '../../../EventBus';
import { MISSION_EVENTS } from '../../../missionEvents';
import { getCurrentMission, getCurrentSession } from '../../../dataModelHelpers';
import { colorToString, findMaxLat } from '../../../utils';

import { MAP_TYPES, MapType } from '../helpers/mapTypes';
import Feature, { FeatureLike } from 'ol/Feature';
import { SimpleGeometry } from 'ol/geom';
import { DefaultKMLFeatureProperties, SelectCandidate, KMLFeature, JobType } from '../../../types/types';
import CorePoint from '../../../db/CorePointClass';
import { Coordinate } from 'ol/coordinate';
import { ModifyEvent } from 'ol/interaction/Modify';
import { SESSION_EVENTS } from '../../../sessionEvents';
import { ZoneRecordingType } from '../../../db/ZoneTypesDatatype';
import { FeatureProps, FeatureReact } from 'react-openlayers/dist/features/feature';
import { Sample, SoilCore } from '../../../db';
import { orderBy } from 'lodash';

import { COLOR, CORE_DEADBAND_WIDTH_M } from '../../../constants';
import { MAP_EVENTS } from '../../../mapEvents';
import { MeasuredCircle } from '../features/measured_circle';
import {
  MapDebugCloseCoreTolerance,
  MapDebugCloseTolerance,
  MapDebugCoreLabels,
  MapDebugCoreWaypointNumbers,
  MapDebugEdgeUnpulledCores,
  MapDebugNoSampleCoreTolerance,
  MapDebugNoSampleTolerance,
  MapDebugPresentCoreTolerance,
  MapDebugPresentTolerance,
  MapPixelsPerMeterStorage,
} from '../../../db/local_storage';

type AddSelectionCandidate = (candidate: SelectCandidate | null) => void;

interface KmlLayerProps {
  addDeleteCandidate: AddSelectionCandidate;
  addSkipCandidate: AddSelectionCandidate;
  addWaypointCandidate: AddSelectionCandidate;
  addMoveCandidate: AddSelectionCandidate;
  showZones: boolean;
  zoneMission: boolean;
  jobType: JobType;
  updatePullin: (pullin: Coordinate) => Promise<void>;
  updatePath: (path: Coordinate[]) => Promise<void>;
  updatePoints: (
    points: Coordinate,
    name: string,
    options?: { addCheckpoint?: boolean; centroid?: boolean },
  ) => Promise<void>;
  updateBoundary: (boundary: Coordinate[][], boundary_type: ZoneRecordingType, zone_id: number) => Promise<void>;
  type: MapType;
  selectInteractionDisabled: boolean;
  modifyInteractionDisabled: boolean;
}

interface KmlLayerState {
  kmlFeatures: (JSX.Element | JSX.Element[])[];
  selectedFeatures: Collection<Feature<SimpleGeometry>>;
  draggableFeatures: Collection<Feature<SimpleGeometry>>;
  presentSampleId: string;
  presentSampleIdPrevious: string;
  closeSampleId: string;
  closeSampleIdPrevious: string;
}

export default class KmlLayer extends PureComponent<KmlLayerProps, KmlLayerState> {
  Z_INDEX_LAST = -1;
  Z_INDEX_ZONE = 0;
  Z_INDEX_FIELD = 1;
  Z_INDEX_PATH = 1;
  Z_INDEX_COLLECTED_FIELD = 2;
  Z_INDEX_PULLIN = 3;
  Z_INDEX_CORE = 3;
  Z_INDEX_FIRST_LAST = 3;
  Z_INDEX_CENTROID = 4;

  SELECTION_PRIORITIES: Record<KmlFeatureType, number> = {
    [KML_FEATURE_TYPE.GENERIC_ZONE]: this.Z_INDEX_ZONE,
    [KML_FEATURE_TYPE.SAMPLE_ZONE]: this.Z_INDEX_ZONE,
    [KML_FEATURE_TYPE.SLOW]: this.Z_INDEX_ZONE,
    [KML_FEATURE_TYPE.UNSAFE]: this.Z_INDEX_ZONE,

    [KML_FEATURE_TYPE.PATH]: this.Z_INDEX_PATH,
    [KML_FEATURE_TYPE.FIELD]: this.Z_INDEX_FIELD,
    [KML_FEATURE_TYPE.COLLECTED_FIELD]: this.Z_INDEX_COLLECTED_FIELD,
    [KML_FEATURE_TYPE.CORE_POINT]: this.Z_INDEX_CORE,
    [KML_FEATURE_TYPE.PULLIN]: this.Z_INDEX_PULLIN,
    [KML_FEATURE_TYPE.CENTROID]: this.Z_INDEX_CENTROID,

    [KML_FEATURE_TYPE.REORDER_LINE]: this.Z_INDEX_LAST,
    [KML_FEATURE_TYPE.CORE_LINE]: this.Z_INDEX_LAST,
    [KML_FEATURE_TYPE.DUMP_EVENT]: this.Z_INDEX_LAST,
  };

  // TODO need to fix this final map (Why is this so hard?)
  // MAP_ACTIONS: Record<MapType, Record<JobType, FeatureActionMap> | FeatureActionMap> = {
  //     'sampling': {
  //         [KML_FEATURE_TYPE.CENTROID]: ['Delete', 'Select'],
  //         [KML_FEATURE_TYPE.GENERIC_ZONE]: ['Delete', 'Select'],
  //         [KML_FEATURE_TYPE.SLOW]: [],
  //         [KML_FEATURE_TYPE.UNSAFE]: [],
  //         [KML_FEATURE_TYPE.COLLECTED_FIELD]: [],
  //         [KML_FEATURE_TYPE.SAMPLE_ZONE]: [],
  //         'Grid': {
  //         },
  //         'Zone': {
  //             [KML_FEATURE_TYPE.CORE_POINT]: [],
  //         },
  //         'Modgrid': {
  //         },
  //     },
  //     'maps': {
  //         'Grid': {
  //         },
  //         'Zone': {
  //         },
  //         'Modgrid': {
  //         },
  //     },
  //     'all': {
  //         'Grid': {
  //         },
  //         'Zone': {
  //         },
  //         'Modgrid': {
  //         },
  //     }
  // }

  DELETEABLE_FEATURES_BY_JOB: Record<JobType, KmlFeatureType[]> = {
    Grid: [
      KML_FEATURE_TYPE.FIELD,
      KML_FEATURE_TYPE.CENTROID,
      KML_FEATURE_TYPE.GENERIC_ZONE,
      KML_FEATURE_TYPE.SLOW,
      KML_FEATURE_TYPE.UNSAFE,
      KML_FEATURE_TYPE.COLLECTED_FIELD,
      KML_FEATURE_TYPE.SAMPLE_ZONE,
    ],
    Zone: [
      KML_FEATURE_TYPE.FIELD,
      KML_FEATURE_TYPE.CENTROID,
      KML_FEATURE_TYPE.GENERIC_ZONE,
      KML_FEATURE_TYPE.SLOW,
      KML_FEATURE_TYPE.UNSAFE,
      KML_FEATURE_TYPE.COLLECTED_FIELD,
      KML_FEATURE_TYPE.SAMPLE_ZONE,
      KML_FEATURE_TYPE.CORE_POINT,
    ],
    Modgrid: [
      KML_FEATURE_TYPE.CENTROID,
      KML_FEATURE_TYPE.GENERIC_ZONE,
      KML_FEATURE_TYPE.SLOW,
      KML_FEATURE_TYPE.UNSAFE,
      KML_FEATURE_TYPE.COLLECTED_FIELD,
      KML_FEATURE_TYPE.SAMPLE_ZONE,
    ],
  };

  SELECTABLE_FEATURES: KmlFeatureType[] = [
    KML_FEATURE_TYPE.CENTROID,
    KML_FEATURE_TYPE.GENERIC_ZONE,
    KML_FEATURE_TYPE.SLOW,
    KML_FEATURE_TYPE.SAMPLE_ZONE,
    KML_FEATURE_TYPE.CORE_POINT,
    KML_FEATURE_TYPE.UNSAFE,
    KML_FEATURE_TYPE.COLLECTED_FIELD,
    KML_FEATURE_TYPE.FIELD,
    KML_FEATURE_TYPE.PULLIN,
    KML_FEATURE_TYPE.PATH,
  ];

  NON_DRAGGABLE_FEATURES_BY_MAP: Record<MapType, KmlFeatureType[]> = {
    sampling: [KML_FEATURE_TYPE.SAMPLE_ZONE, KML_FEATURE_TYPE.CENTROID, KML_FEATURE_TYPE.CORE_POINT],
    maps: [KML_FEATURE_TYPE.SAMPLE_ZONE],
    all: [],
  };

  NON_DRAGGABLE_FEATURES_BY_JOB: Record<JobType, KmlFeatureType[]> = {
    Grid: [KML_FEATURE_TYPE.CORE_POINT],
    Zone: [],
    Modgrid: [],
  };

  WAYPOINT_SETTABLE_FEATURES_BY_JOB: Record<JobType, KmlFeatureType[]> = {
    Grid: [KML_FEATURE_TYPE.CENTROID],
    Zone: [KML_FEATURE_TYPE.SAMPLE_ZONE, KML_FEATURE_TYPE.CORE_POINT],
    Modgrid: [KML_FEATURE_TYPE.CENTROID],
  };

  SKIP_SETTABLE_FEATURES_BY_JOB: Record<JobType, KmlFeatureType[]> = {
    Grid: [KML_FEATURE_TYPE.CENTROID],
    Zone: [KML_FEATURE_TYPE.SAMPLE_ZONE, KML_FEATURE_TYPE.CORE_POINT],
    Modgrid: [KML_FEATURE_TYPE.CENTROID],
  };

  POINT_MOVABLE_FEATURES_BY_JOB: Record<JobType, KmlFeatureType[]> = {
    Grid: [KML_FEATURE_TYPE.CENTROID],
    Zone: [KML_FEATURE_TYPE.CORE_POINT],
    Modgrid: [KML_FEATURE_TYPE.CENTROID],
  };

  selectable: boolean;
  // TODO not 100% sure about this typing...
  pendingSelectFeatures: Feature<SimpleGeometry>[];
  prevFeatures: {
    waypoints?: {
      waypointData: string;
      waypointFeatures: KMLFeature;
    };
    settings?: {
      showPulledCoreLocation?: boolean;
    };
  };
  initKmlTimeoutId: NodeJS.Timeout;
  prevSelectedFeature: Collection<any>;
  deselectTimer: NodeJS.Timeout | undefined;
  handleNothingTimeout: NodeJS.Timeout;
  nextHandleId: NodeJS.Timeout;

  constructor(props: KmlLayerProps) {
    super(props);

    this.state = {
      kmlFeatures: [],
      selectedFeatures: new Collection([]),
      draggableFeatures: new Collection([]),
      presentSampleId: '',
      presentSampleIdPrevious: '',
      closeSampleId: '',
      closeSampleIdPrevious: '',
    };

    this.handleSelectFeature = this.handleSelectFeature.bind(this);
    this.initKml = this.initKml.bind(this);
    this.removeFeatureSelection = this.removeFeatureSelection.bind(this);
    this.handleNothingClicked = this.handleNothingClicked.bind(this);
    this.onCloseSampleNeedsUpdate = this.onCloseSampleNeedsUpdate.bind(this);

    this.selectable = true;
    this.pendingSelectFeatures = [];
    this.prevFeatures = {};
  }

  onCloseSampleNeedsUpdate() {
    if (this.props.type === MAP_TYPES.MAPS) {
      return;
    }

    // This will update only the cores of a close sample and a present sample.
    // That is all we care about at this point. We are only
    // callling this because we need to update the tolerance
    // radiuses of the circles when the zoom changes.
    // Ohter circles will have wrong radiuses....but we don't care
    // because we are not coring near them.
    const sampleIds = new Set();
    if (this.state.closeSampleId) {
      sampleIds.add(this.state.closeSampleId);
    }

    if (this.state.presentSampleId) {
      sampleIds.add(this.state.presentSampleId);
    }

    if (!sampleIds.size) {
      return;
    }

    this.updateSamples(JSON.stringify({ sampleIds: Array.from(sampleIds) }));
  }

  componentDidMount() {
    EventBus.on(MISSION_EVENTS.UPDATED, this.initKml);
    EventBus.on(MAP_EVENTS.CORES_NEED_UPDATE, this.initKml);
    EventBus.on(MAP_EVENTS.CLOSE_SAMPLE_NEEDS_UPDATE, this.onCloseSampleNeedsUpdate);
    EventBus.on(SESSION_EVENTS.UPDATED_CURRENT_SAMPLE, this.updatedCurrentSample);
    EventBus.on('UPDATE_NEXT_CORE', this.updateNextCore);
    EventBus.on(MAP_EVENTS.SAMPLES_UPDATED, this.updateSamples);

    EventBus.on(MAP_EVENTS.SAMPLE_ARRIVED, this.updatePresentSample, true);
    EventBus.on(MAP_EVENTS.SAMPLE_LEFT, this.leavingSample, true);

    EventBus.on(MAP_EVENTS.CLOSE_SAMPLE_ARRIVED, this.updateCloseSample, true);
    EventBus.on(MAP_EVENTS.CLOSE_SAMPLE_LEFT, this.leavingCloseSample, true);

    // DEBUG when using core labels on samples
    //EventBus.on('CORE_SUCCESS', this.initKml);
    //EventBus.on('DUMP_SUCCESS', this.initKml);

    this.initKmlTimeoutId = setTimeout(() => this.initKml(), 400);
    this.prevSelectedFeature = new Collection([]);
  }

  componentDidUpdate(prevProps: KmlLayerProps, prevState: KmlLayerState) {
    const selectionChanged =
      JSON.stringify(prevState.selectedFeatures.getArray()) !== JSON.stringify(this.state.selectedFeatures.getArray());

    if (selectionChanged) {
      this.prevSelectedFeature = prevState.selectedFeatures;
      this.initKml({ selectedChange: true });
    } else if (this.state.presentSampleId !== prevState.presentSampleId) {
      this.updateSamples(JSON.stringify({ sampleIds: [this.state.presentSampleId, prevState.presentSampleId] }));
    } else if (this.state.closeSampleId !== prevState.closeSampleId) {
      this.updateSamples(JSON.stringify({ sampleIds: [this.state.closeSampleId, prevState.closeSampleId] }));
    }

    const zoneVisibilityChanged = prevProps.showZones !== this.props.showZones;
    if (zoneVisibilityChanged) {
      this.initKml();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.initKmlTimeoutId);
    this.state.selectedFeatures.dispose();
    this.prevSelectedFeature.dispose();
    this.prevFeatures = {};
    EventBus.remove(MISSION_EVENTS.UPDATED, this.initKml);
    EventBus.remove(MAP_EVENTS.CORES_NEED_UPDATE, this.initKml);
    EventBus.remove(MAP_EVENTS.CLOSE_SAMPLE_NEEDS_UPDATE, this.onCloseSampleNeedsUpdate);
    EventBus.remove(SESSION_EVENTS.UPDATED_CURRENT_SAMPLE, this.updatedCurrentSample);
    EventBus.remove('UPDATE_NEXT_CORE', this.updateNextCore);
    EventBus.remove(MAP_EVENTS.SAMPLES_UPDATED, this.updateSamples);

    EventBus.remove(MAP_EVENTS.SAMPLE_ARRIVED, this.updatePresentSample);
    EventBus.remove(MAP_EVENTS.SAMPLE_LEFT, this.leavingSample);

    EventBus.remove(MAP_EVENTS.CLOSE_SAMPLE_ARRIVED, this.updateCloseSample);
    EventBus.remove(MAP_EVENTS.CLOSE_SAMPLE_LEFT, this.leavingCloseSample);

    // DEBUG for debugging purposes when using core labels on samples
    //EventBus.remove('CORE_SUCCESS', this.initKml);
    //EventBus.remove('DUMP_SUCCESS', this.initKml);
  }

  updateSamples = (sampleIdsString?: string) => {
    const pixelsPerMeter = MapPixelsPerMeterStorage.get();

    const sampleIdsToReRender: string[] = [];
    if (sampleIdsString) {
      const { sampleIds } = JSON.parse(sampleIdsString) as { sampleIds: string[] };
      if (!sampleIds || !Array.isArray(sampleIds)) {
        console.error('KmlLayer: sampleIds is not an array', sampleIds);
      } else {
        sampleIdsToReRender.push(...sampleIds);
      }
    }

    const mission = getCurrentMission();
    if (!mission) {
      return;
    }

    let kmlFeatures = this.state.kmlFeatures.slice();
    const prevFeatures = { ...this.prevFeatures };
    const featuresObj = mission.to_feature(prevFeatures);

    const session = getCurrentSession();
    const corePoints = mission.getWaypoints().getCorePoints();
    const activeSample = session?.getSample();
    const activeSampleId = activeSample && activeSample.sample_id;

    // const { dumped, undumped } = getCoreMap();
    let filteredFeatures = kmlFeatures.filter((feature) => {
      const f = (Array.isArray(feature) ? feature[0] : feature) as unknown as FeatureReact<FeatureProps>;
      return f && f.props && f.props.properties && f.props.properties.index;
    });

    if (this.props.zoneMission) {
      filteredFeatures = filteredFeatures.filter((feature) => {
        const f = (Array.isArray(feature) ? feature[0] : feature) as unknown as FeatureReact<FeatureProps>;
        return (
          f.props.properties?.core_location &&
          (!sampleIdsToReRender.length || sampleIdsToReRender.includes(f.props.properties.sample_id))
        );
      });
    } else {
      filteredFeatures = filteredFeatures.filter((feature) => {
        const f = (Array.isArray(feature) ? feature[0] : feature) as unknown as FeatureReact<FeatureProps>;

        // Rerender a sample and its cores
        return (
          (f.props.properties?.centroid || f.props.properties?.core_location) &&
          (!sampleIdsToReRender.length ||
            sampleIdsToReRender.includes(f.props.properties?.name) ||
            sampleIdsToReRender.includes(f.props.properties?.sample_id))
        );
      });
    }

    filteredFeatures.forEach((feature) => {
      const f = (Array.isArray(feature) ? feature[0] : feature) as unknown as FeatureReact<FeatureProps>;
      const index = f.props.properties?.index;
      kmlFeatures[index] = this.genKmlFeature(
        featuresObj.features[index],
        corePoints,
        activeSampleId,
        index,
        pixelsPerMeter,
      );
    });

    // featuresObj.features.filter((feature) => feature.properties.centroid).forEach((feature) => {
    //     kmlFeatures[feature.properties.index] = this.genKmlFeature(
    //             feature,
    //             corePoints,
    //             activeSampleId,
    //             feature.properties.index,
    //             dumped,
    //             undumped,
    //         );
    // });
    this.setState({ kmlFeatures });
  };

  updateNextCore = async ({ sampleId: string, coreNumber: number }) => {};

  updatedCurrentSample = async (instanceIdsString: string) => {
    const instanceIds = JSON.parse(instanceIdsString || '[]') as number[];
    this.updateSamples(
      JSON.stringify({ sampleIds: instanceIds.filter((id) => !!id).map((id) => Sample.get(id)?.sample_id) }),
    );
  };

  leavingSample = async (sampleId: string) => {
    if (this.state.presentSampleId === sampleId) {
      this.setState({ presentSampleId: '' });
    }
  };

  updatePresentSample = async (sampleId: string) => {
    if (this.state.presentSampleId === sampleId) {
      return;
    }

    let presentSampleIdPrevious = '';
    if (this.state.presentSampleId) {
      presentSampleIdPrevious = this.state.presentSampleId;
    }
    this.setState({ presentSampleId: sampleId, presentSampleIdPrevious });
  };

  leavingCloseSample = async (sampleId: string) => {
    if (this.state.closeSampleId === sampleId) {
      this.setState({ closeSampleId: '' });
    }
  };

  updateCloseSample = async (sampleId: string) => {
    if (this.state.closeSampleId === sampleId) {
      return;
    }

    let closeSampleIdPrevious = '';
    if (this.state.closeSampleId) {
      closeSampleIdPrevious = this.state.closeSampleId;
    }
    this.setState({ closeSampleId: sampleId, closeSampleIdPrevious });
  };
  /**
   * Init the kml features
   * This really isn't ideal because right now we're trigger this off of
   * various event bus events and basically mangling the arguments to this
   * function, which is why someone designed this with === true cases
   * instead of just a boolean check. This should be revisted and refactored
   * better than this
   */
  initKml(options?: { selectedChange?: boolean }) {
    const pixelsPerMeter = MapPixelsPerMeterStorage.get();

    const mission = getCurrentMission();
    if (!mission) {
      this.setState({ kmlFeatures: [] });

      return;
    }

    const session = getCurrentSession();
    const missionCorePoints = orderBy(mission.getWaypoints().getCorePoints(), ['waypoint_number']);
    const prevFeatures = { ...this.prevFeatures };
    const activeSample = session?.getSample();
    const activeSampleId = activeSample && activeSample.sample_id;

    if (options?.selectedChange === true) {
      console.log(`KmlLayer initKml selectedChange`);
      const selected = this.state.selectedFeatures.getArray()[0];
      const prevSelected = this.prevSelectedFeature.getArray()[0];
      let kmlFeatures = this.state.kmlFeatures.slice();
      if (selected) {
        const feature = transformObjectOl(selected);
        kmlFeatures[feature.properties.index] = this.genKmlFeature(
          feature,
          missionCorePoints,
          activeSampleId,
          feature.properties.index,
          pixelsPerMeter,
        );
        //console.log('NEW GENERATED', this.genKmlFeature(feature, corePoints, activeSampleId, feature.properties.index));
      }
      if (prevSelected) {
        const feature = transformObjectOl(prevSelected);
        kmlFeatures[feature.properties.index] = this.genKmlFeature(
          feature,
          missionCorePoints,
          activeSampleId,
          feature.properties.index,
          pixelsPerMeter,
        );
        //console.log('NEW GENERATED', this.genKmlFeature(feature, corePoints, activeSampleId, feature.properties.index));
      }
      this.setState({ kmlFeatures });
    } else {
      const featuresObj = mission.to_feature(prevFeatures);
      this.prevFeatures = prevFeatures;

      const kmlFeatures = featuresObj.features.map((feature, index) =>
        this.genKmlFeature(feature, missionCorePoints, activeSampleId, index, pixelsPerMeter),
      );

      this.setState({ kmlFeatures });
      this.checkSelectNeedsUpdate(featuresObj.features);
    }
  }

  checkSelectNeedsUpdate(kmlFeatures: KMLFeature<DefaultKMLFeatureProperties>[]) {
    if (this.state.selectedFeatures.getArray().length) {
      const firstSelected = this.state.selectedFeatures.getArray()[0];
      const firstSelectedType = getFeatureType(firstSelected.getProperties());
      const actions = [
        [this.DELETEABLE_FEATURES_BY_JOB[this.props.jobType], this.props.addDeleteCandidate] as const,
        [this.WAYPOINT_SETTABLE_FEATURES_BY_JOB[this.props.jobType], this.props.addWaypointCandidate] as const,
        [this.SKIP_SETTABLE_FEATURES_BY_JOB[this.props.jobType], this.props.addSkipCandidate] as const,
        [this.POINT_MOVABLE_FEATURES_BY_JOB[this.props.jobType], this.props.addMoveCandidate] as const,
      ];
      for (let kmlFeature of kmlFeatures) {
        if (kmlFeature.properties) {
          const featureType = getFeatureType(kmlFeature.properties);
          if (featureType && featureType === firstSelectedType) {
            if (
              getKey(kmlFeature.properties, featureType) === getKey(firstSelected.getProperties(), firstSelectedType)
            ) {
              for (const [ACTIONABLE_FEATURES, addCandidate] of actions) {
                if (ACTIONABLE_FEATURES.includes(firstSelectedType)) {
                  addCandidate({
                    feature: firstSelected,
                    type: firstSelectedType,
                    removeFunc: this.removeFeatureSelection,
                    // TODO we should be able to remove this .coordiante reference, no other refernces to this
                    // property are found throughout the code. This likely always works because we almost always
                    // have coordinates
                    coordinates: findMaxLat(kmlFeature.geometry.coordinates || kmlFeature.geometry.coordinates || [])!,
                  });
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Handle react component generation based on feature type
   * @param {object} kmlFeature GeoJSON object
   */
  genKmlFeature(
    kmlFeature: KMLFeature<DefaultKMLFeatureProperties>,
    missionCorePoints: CorePoint[],
    activeSampleId: string | undefined,
    index: number,
    pixelsPerMeter: number,
  ) {
    const featureType = getFeatureType(kmlFeature.properties);
    kmlFeature.properties.index = index;
    //console.log(kmlFeature.properties.index);
    switch (featureType) {
      case KML_FEATURE_TYPE.PULLIN:
        return this.genPullinFeature(kmlFeature);
      case KML_FEATURE_TYPE.FIELD:
        return this.genFieldFeature(kmlFeature);
      case KML_FEATURE_TYPE.PATH:
        return this.genPathFeature(kmlFeature);
      case KML_FEATURE_TYPE.COLLECTED_FIELD:
        return this.genCollectedFieldFeature(kmlFeature);
      case KML_FEATURE_TYPE.CORE_POINT:
        return this.genCorePointFeatures(kmlFeature, missionCorePoints, activeSampleId, pixelsPerMeter).flat();
      case KML_FEATURE_TYPE.DUMP_EVENT:
        return this.genDumpEventFeature(kmlFeature);
      case KML_FEATURE_TYPE.CENTROID:
        return this.genCentroidFeature(kmlFeature, activeSampleId, pixelsPerMeter);
      case KML_FEATURE_TYPE.GENERIC_ZONE:
        return this.genGenericFeature(kmlFeature);
      case KML_FEATURE_TYPE.SLOW:
        return this.genSlowFeature(kmlFeature);
      case KML_FEATURE_TYPE.UNSAFE:
        return this.genUnsafeFeature(kmlFeature);
      case KML_FEATURE_TYPE.SAMPLE_ZONE:
        return this.genSampleZone(kmlFeature);
      default:
        // console.warn('Could not gen feature: ', kmlFeature);
        throw Error('Could not gen feature');
    }
  }

  genDumpEventFeature(kmlFeature: KMLFeature<any>) {
    const coords = kmlFeature.geometry.coordinates;
    if (!coords) {
      return <></>;
    }

    const dumpText = kmlFeature.properties.name + '\n' + kmlFeature.properties.core_ids.join(', ');

    return (
      <feature.PointReact
        coordinate={coords}
        circleOptions={{
          radius: 3,
          strokeOptions: { color: 'pink', width: 5 },
        }}
        key={dumpText}
        zIndex={this.Z_INDEX_CORE}
        textOptions={{
          text: dumpText,
          font: '18px Calibri,sans-serif',
          fillOptions: { color: WHITE },
        }}
        hideAtZoom={this.props.zoneMission ? 14 : 20}
        hideTextZoom={16}
        properties={kmlFeature.properties}
      />
    );
  }

  genCorePointFeatures(
    kmlFeature: KMLFeature<DefaultKMLFeatureProperties>,
    missionCorePoints: CorePoint[],
    activeSampleId: string | undefined,
    pixelsPerMeter: number,
  ) {
    if (!missionCorePoints.length) {
      return this.genCorePointFeature(undefined, missionCorePoints, kmlFeature, activeSampleId, pixelsPerMeter);
    }

    const corePoint = missionCorePoints.find(
      (corePoint) => corePoint.waypoint_number === kmlFeature.properties.waypoint_index + 1,
    );

    const out: JSX.Element[] = [];
    const isFirstCorePoint = corePoint === missionCorePoints[0];
    if (isFirstCorePoint) {
      const firstPointElement = this.genFirstLastPointFlag(kmlFeature, true);
      if (firstPointElement) {
        out.push(firstPointElement);
      }
    }

    // last corePoint
    const isLastCorePoint = corePoint === missionCorePoints[missionCorePoints.length - 1];
    if (isLastCorePoint) {
      const lastPointElement = this.genFirstLastPointFlag(kmlFeature, false);
      if (lastPointElement) {
        out.push(lastPointElement);
      }
    }

    return this.genCorePointFeature(corePoint, missionCorePoints, kmlFeature, activeSampleId, pixelsPerMeter).concat(
      out,
    );
  }

  private getFirstOrLastUnpulled(
    corePoint: CorePoint | undefined,
    kmlFeature: KMLFeature<DefaultKMLFeatureProperties, { coordinates?: any[] }>,
    allCorePoints: CorePoint[],
  ) {
    const isPulled = !!corePoint?.getSoilCore()?.pulled;
    const isUnpulled = !isPulled;

    let firstOrLastUnpulled = isUnpulled;
    if (!firstOrLastUnpulled) {
      return false;
    }

    // if the core point is unpulled, check if it is the first or last unpulled core point.
    // Only do the check for the close sample - otherwise there are too many
    // checks for lots of cores and it hits the performance.
    if (this.state.closeSampleId !== kmlFeature.properties.sample_id) {
      return false;
    }

    const sampleCorePoints = allCorePoints.filter(
      (corePoint) => corePoint.getSoilCore()?.getSample()?.sample_id === kmlFeature.properties.sample_id,
    );

    const unpulledBefore = sampleCorePoints.find(
      (corePoint) =>
        corePoint.waypoint_number &&
        corePoint.waypoint_number < kmlFeature.properties.waypoint_index + 1 &&
        !corePoint.getSoilCore()?.pulled,
    );

    const unpulledAfter = sampleCorePoints.find(
      (corePoint) =>
        corePoint.waypoint_number &&
        corePoint.waypoint_number > kmlFeature.properties.waypoint_index + 1 &&
        !corePoint.getSoilCore()?.pulled,
    );

    firstOrLastUnpulled = !unpulledBefore || !unpulledAfter;

    return firstOrLastUnpulled;
  }

  genSampleZone(kmlFeature: KMLFeature<DefaultKMLFeatureProperties>) {
    const key = getZoneKey(kmlFeature.properties);
    const { selectedFeature, featureType } = getSelectedFeature(this.state.selectedFeatures);
    const selected =
      featureType === KML_FEATURE_TYPE.SAMPLE_ZONE && key === getZoneKey(selectedFeature.getProperties());
    const coords = kmlFeature.geometry.coordinates;

    if (!coords) {
      return <></>;
    }

    return this.props.showZones ? (
      <feature.PolygonReact
        coordinates={coords}
        fillOptions={{ color: kmlFeature.properties.zone_color.concat([0.3]) }}
        strokeOptions={{ color: BLACK, width: selected ? 2 : 1 }}
        // @ts-ignore
        key={`${key}-${kmlFeature.properties.index ?? 0}-${KML_FEATURE_TYPE.SAMPLE_ZONE}`}
        properties={kmlFeature.properties}
        zIndex={this.Z_INDEX_ZONE}
        hideAtZoom={14}
        textOptions={{
          text: `${kmlFeature.properties.name}`,
          font: '20px Calibri,sans-serif',
          fillOptions: { color: BLACK },
        }}
      />
    ) : (
      <></>
    );
  }

  /**
   * Generate the pullin point for the field
   * @param {object} kmlFeature GeoJSON object
   * @returns {PointReact}
   */
  genPullinFeature(kmlFeature: KMLFeature<DefaultKMLFeatureProperties>) {
    const key = getPullinKey(kmlFeature.properties);
    const { selectedFeature, featureType } = getSelectedFeature(this.state.selectedFeatures);
    const selected = featureType === KML_FEATURE_TYPE.PULLIN && key === getPullinKey(selectedFeature.getProperties());
    const coords = kmlFeature.geometry.coordinates;
    if (!coords) {
      return <></>;
    }
    return (
      <feature.PointReact
        coordinate={coords}
        circleOptions={{
          radius: selected ? 10 : 8,
          fillOptions: { color: GREEN },
          strokeOptions: { color: BLACK, width: 2 },
        }}
        properties={kmlFeature.properties}
        key={`${key}-${KML_FEATURE_TYPE.PULLIN}`}
        zIndex={this.Z_INDEX_PULLIN}
      />
    );
  }

  /**
   * Generate the field boundary+
   * @param {object} kmlFeature GeoJSON object
   * @returns {MultiPolygonReact | PolygonReact}
   */
  genFieldFeature(kmlFeature: KMLFeature<DefaultKMLFeatureProperties>) {
    const key = getFieldKey(kmlFeature.properties);
    const { selectedFeature, featureType } = getSelectedFeature(this.state.selectedFeatures);
    const selected = featureType === KML_FEATURE_TYPE.FIELD && key === getFieldKey(selectedFeature.getProperties());
    const FieldFeature =
      kmlFeature.geometry.type === GeometryType.MULTI_POLYGON ? feature.MultiPolygonReact : feature.PolygonReact;

    const coords = kmlFeature.geometry.coordinates;
    if (!coords) {
      return <></>;
    }

    return (
      <FieldFeature
        coordinates={coords}
        strokeOptions={{ color: YELLOW, width: selected ? 16 : 3 }}
        properties={kmlFeature.properties}
        key={`${key}-${KML_FEATURE_TYPE.FIELD}-${kmlFeature.properties.zone_id}`}
        zIndex={this.Z_INDEX_FIELD}
      />
    );
  }

  /**
   * Generate path for field
   * @param {object} kmlFeature GeoJSON object
   * @returns {LineStringReact}
   */
  genPathFeature(kmlFeature: KMLFeature<DefaultKMLFeatureProperties>, color = WHITE) {
    const key = getPathKey(kmlFeature.properties);
    const { selectedFeature, featureType } = getSelectedFeature(this.state.selectedFeatures);
    const selected = featureType === KML_FEATURE_TYPE.PATH && key === getPathKey(selectedFeature.getProperties());
    const uniqueKey = `${key}-${KML_FEATURE_TYPE.PATH}`;
    const coords = kmlFeature.geometry.coordinates;
    if (!coords) {
      return <></>;
    }
    return (
      <feature.LineStringReact
        coordinates={coords}
        strokeOptions={{ color, width: selected ? 4 : 2 }}
        properties={kmlFeature.properties}
        key={uniqueKey}
        zIndex={this.Z_INDEX_PATH}
        forceRefresh={true}
        hideAtZoom={14}
      />
    );
  }

  /**
   * Generate collected field boundary
   * @param {object} kmlFeature GeoJSON object
   */
  genCollectedFieldFeature(kmlFeature: KMLFeature<DefaultKMLFeatureProperties>) {
    const key = getCollectedFieldKey(kmlFeature.properties);
    const { selectedFeature, featureType } = getSelectedFeature(this.state.selectedFeatures);
    const selected =
      featureType === KML_FEATURE_TYPE.COLLECTED_FIELD && key === getCollectedFieldKey(selectedFeature.getProperties());
    const uniqueKey = `${key}-${KML_FEATURE_TYPE.COLLECTED_FIELD}-${selectedFeature?.getProperties()?.poly_id}`;
    const coords = kmlFeature.geometry.coordinates;
    if (!coords) {
      return <></>;
    }
    return (
      <feature.PolygonReact
        coordinates={coords}
        strokeOptions={{ color: LIGHT_BLUE, width: selected ? 6 : 4 }}
        properties={kmlFeature.properties}
        key={uniqueKey}
        zIndex={this.Z_INDEX_COLLECTED_FIELD}
      />
    );
  }

  genCoreMapDebugFeatures(
    missionCorePoint: CorePoint | undefined,
    allCorePoints: CorePoint[],
    kmlFeature: KMLFeature<DefaultKMLFeatureProperties>,
    pixelsPerMeter: number,
  ): JSX.Element[] {
    let firstOrLastUnpulled = this.getFirstOrLastUnpulled(missionCorePoint, kmlFeature, allCorePoints);
    // if we're serializing cores, we have a mission
    const mission = getCurrentMission()!;
    // TODO should potentially pass these in the functions after being retrieved above
    const presentTolerance = mission.get_present_tolerance();
    const closeTolerance = mission.get_close_tolerance();
    const pulled = kmlFeature.properties.pulled;

    if (!MapDebugEdgeUnpulledCores.get()) {
      firstOrLastUnpulled = !pulled;
    }

    const coords = kmlFeature.geometry.coordinates;

    if (!coords) {
      return [];
    }

    const showCoreToleranceCircle = (firstOrLastUnpulled: boolean) => {
      if (!MapDebugPresentCoreTolerance.get()) {
        return false;
      }

      if (mission.is_zone_mission()) {
        return true;
      }

      if (!firstOrLastUnpulled) {
        return false;
      }

      // At this point we know that it is an edge core.

      const sample = mission
        .getSamples()
        .find((sample: Sample) => sample.sample_id === kmlFeature.properties.sample_id);
      const sampleHasPulledCores = sample ? sample.hasCoresPulled() : false;
      const edgeCoreWaypointNumber = kmlFeature.properties.waypoint_number;

      // Show both edge cores if sample doesn't have any cores pulled yet.
      if (!sampleHasPulledCores) {
        return true;
      }

      // If sample has cores pulled, show only one edge core (the other one is not
      // allowed to be cored due to Soil Mix Risk - see Sample.getNextSoilTarget).
      // Show the one that has an adjacent core pulled.
      const foundAdjasentCorePulled = sample!.getSoilCores().some((core: SoilCore) => {
        return (
          core.pulled &&
          (core.waypoint_number === edgeCoreWaypointNumber + 1 || core.waypoint_number === edgeCoreWaypointNumber - 1)
        );
      });

      return foundAdjasentCorePulled;
    };

    return [
      MapDebugCloseCoreTolerance.get() && !pulled && (
        <MeasuredCircle
          key={`${kmlFeature.properties.name}-close-core-tolerance`}
          center={coords}
          color="rgba(255, 128, 0, 0.2)"
          circleMeasurements
          innerRadiusMeters={presentTolerance}
          outerRadiusMeters={closeTolerance}
          pixelsPerMeter={pixelsPerMeter}
        />
      ),
      showCoreToleranceCircle(firstOrLastUnpulled) && (
        <MeasuredCircle
          key={`${kmlFeature.properties.name}-core-tolerance`}
          center={coords}
          color={pulled ? '#ffa500' : 'rgba(255, 255, 0, 1)'}
          innerRadiusMeters={presentTolerance}
          outerRadiusMeters={presentTolerance}
          pixelsPerMeter={pixelsPerMeter}
        />
      ),
      MapDebugNoSampleCoreTolerance.get() && !pulled && (
        <MeasuredCircle
          key={`${kmlFeature.properties.name}-no-sample-core-tolerance`}
          center={coords}
          color="rgba(255, 0, 0, 0.2)"
          circleMeasurements
          innerRadiusMeters={presentTolerance}
          outerRadiusMeters={presentTolerance + CORE_DEADBAND_WIDTH_M}
          pixelsPerMeter={pixelsPerMeter}
        />
      ),
    ].filter((sel) => !!sel) as JSX.Element[];
  }

  GREEN = '#43a047';
  WHITE = '#FFFFFF';
  DARK_YELLOW = '#8D6708';
  BLUE = '#1565C0';
  /**
   * Generates a core point
   */
  genCorePointFeature(
    missionCorePoint: CorePoint | undefined,
    allCorePoints: CorePoint[],
    kmlFeature: KMLFeature<DefaultKMLFeatureProperties>,
    activeSampleId: string | undefined,
    pixelsPerMeter: number,
  ): JSX.Element[] {
    const key = getCorePointKey(kmlFeature.properties);
    const sampled = this.props.zoneMission && Boolean(kmlFeature.properties.bag_id);
    const selectedSample =
      this.props.zoneMission &&
      kmlFeature.properties.sample_id === activeSampleId &&
      this.props.type === MAP_TYPES.SAMPLING;

    const uniqueKey = `${key}-${KML_FEATURE_TYPE.CORE_POINT}`;
    const pulled = kmlFeature.properties.pulled;

    const isZoneMission = this.props.zoneMission;
    const showCoreLabels = isZoneMission || MapDebugCoreLabels.get();

    let fillColor: string;
    const skipped: boolean = kmlFeature.properties.skipped;
    if (skipped) {
      fillColor = colorToString(COLOR.RED);
    } else if (selectedSample) {
      fillColor = colorToString(COLOR.BLUE);
    } else if (sampled) {
      fillColor = colorToString(COLOR.GREEN);
    } else if (pulled) {
      fillColor = colorToString(COLOR.ORANGE);
    } else {
      fillColor = colorToString(COLOR.WHITE);
    }

    if (!kmlFeature.geometry.coordinates) {
      return [];
    }

    const showWaypointNumbers = MapDebugCoreWaypointNumbers.get();

    let out = [
      <feature.PointReact
        coordinate={kmlFeature.geometry.coordinates}
        circleOptions={{
          // radius: presentSample ? 10 : this.props.zoneMission ? 5 : 3,
          radius: isZoneMission ? 5 : 3,
          fillOptions: { color: fillColor },
          strokeOptions: { color: 'black', width: 1 },
          blinking: selectedSample,
        }}
        key={uniqueKey}
        zIndex={this.Z_INDEX_CORE}
        textOptions={
          showCoreLabels
            ? {
                text: `${kmlFeature.properties.name}${showWaypointNumbers ? ' wp ' + kmlFeature.properties.waypoint_number : ''}`,
                font: '18px Calibri,sans-serif',
                fillOptions: { color: WHITE },
                offsetX: sampled ? 25 : 20,
              }
            : {}
        }
        // textOptions={(this.props.zoneMission || MapDebugStorage.get()) ? {
        //     text: [
        //         kmlFeature.properties.name,
        //         MapDebugStorage.get() ? `(${kmlFeature.properties.source})` : '',
        //         MapDebugStorage.get() ? `(${kmlFeature.properties.pulled ? "Pulled" : "Unpulled"})` : '',
        //     ].join('\n'),
        //     font: '18px Calibri,sans-serif',
        //     fillOptions: { color: WHITE },
        //     offsetX: sampled ? 25 : 20,
        // } : {}}
        hideAtZoom={this.props.zoneMission ? 14 : 20}
        hideTextZoom={16}
        properties={kmlFeature.properties}
      />,
    ];

    if (this.props.type === MAP_TYPES.SAMPLING) {
      out = out.concat(...this.genCoreMapDebugFeatures(missionCorePoint, allCorePoints, kmlFeature, pixelsPerMeter));
    }

    return out;
  }

  genFirstLastPointFlag(kmlFeature: KMLFeature<DefaultKMLFeatureProperties>, first: boolean) {
    // TODO these need to be unique...
    const uniqueKey = `${first ? 'first' : 'last'}`;

    if (!kmlFeature.geometry.coordinates) {
      return null;
    }

    return (
      <feature.PointReact
        coordinate={kmlFeature.geometry.coordinates as Coordinate}
        key={`${uniqueKey}-${kmlFeature.geometry.coordinates![0]}`}
        zIndex={this.Z_INDEX_FIRST_LAST}
        iconOptions={
          first
            ? {
                src: './images/start-flag.png',
                anchor: [0.1, 1.2],
                scale: 0.2,
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                opacity: 1,
              }
            : {
                src: './images/finish-flag.png',
                anchor: [0.1, 1.2],
                scale: 0.2,
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                opacity: 1,
              }
        }
        hideAtZoom={14}
      />
    );
  }

  /**
   * Generates a centroid field
   */
  genCentroidFeature(
    kmlFeature: KMLFeature<DefaultKMLFeatureProperties>,
    activeSampleId: string | undefined,
    pixelsPerMeter: number,
  ) {
    const key = getCentroidKey(kmlFeature.properties);
    const { selectedFeature, featureType } = getSelectedFeature(this.state.selectedFeatures);
    const selected =
      featureType === KML_FEATURE_TYPE.CENTROID && key === getCentroidKey(selectedFeature.getProperties());
    // TODO BARCODE
    const scanned = Boolean(kmlFeature.properties.bag_id);
    const activeSample = kmlFeature.properties.name === activeSampleId && this.props.type === MAP_TYPES.SAMPLING;
    const uniqueKey = `${key}-${KML_FEATURE_TYPE.CENTROID}`;
    const sampleId = kmlFeature.properties.name;
    const skipped = kmlFeature.properties.skipped;
    let centroidLabel = sampleId; // + kmlFeature.properties.source;
    const presentSample = sampleId === this.state.presentSampleId;
    const mission = getCurrentMission()!;
    const presentTolerance = mission.get_present_tolerance();
    if (!kmlFeature.geometry.coordinates) {
      return [];
    }

    return [
      <feature.PointReact
        coordinate={kmlFeature.geometry.coordinates}
        circleOptions={{
          // radius: selected ? 12 : presentSample ? 4 : 8,
          radius: selected ? 12 : 8,
          strokeOptions: { color: 'black', width: 2 },
          fillOptions: { color: skipped ? 'red' : activeSample ? 'blue' : scanned ? '#43a047' : 'white' },
          blinking: presentSample,
        }}
        textOptions={{
          text: centroidLabel,
          font: presentSample ? '24px Calibri,sans-serif' : '16px Calibri,sans-serif',
          fillOptions: { color: WHITE },
          offsetX: presentSample ? 60 : 20,
          offsetY: presentSample ? 0 : 20 * kmlFeature.properties.site_order - 10,
        }}
        properties={{ ...kmlFeature.properties }}
        key={uniqueKey}
        zIndex={this.Z_INDEX_CENTROID}
        hideAtZoom={14}
      />,
      MapDebugCloseTolerance.get() && (
        <MeasuredCircle
          center={kmlFeature.geometry.coordinates}
          color="rgba(255, 128, 0, 0.1)"
          circleMeasurements
          innerRadiusMeters={presentTolerance}
          outerRadiusMeters={mission.get_close_tolerance()}
          pixelsPerMeter={pixelsPerMeter}
        />
      ),
      MapDebugPresentTolerance.get() && (
        <MeasuredCircle
          center={kmlFeature.geometry.coordinates}
          color="rgba(0, 255, 0, 0.1)"
          // circleMeasurements
          outerRadiusMeters={presentTolerance}
          pixelsPerMeter={pixelsPerMeter}
        />
      ),
      MapDebugNoSampleTolerance.get() && (
        <MeasuredCircle
          center={kmlFeature.geometry.coordinates}
          color="rgba(255, 0, 0, 0.1)"
          innerRadiusMeters={presentTolerance}
          outerRadiusMeters={presentTolerance + CORE_DEADBAND_WIDTH_M}
          pixelsPerMeter={pixelsPerMeter}
        />
      ),
    ].filter((element) => !!element);
  }

  /**
   * Generates a slow boundary
   * @param {object} kmlFeature GeoJSON object
   * @returns {PolygonReact}
   */
  genSlowFeature(kmlFeature: KMLFeature<DefaultKMLFeatureProperties>) {
    const key = getZoneKey(kmlFeature.properties);
    const { selectedFeature, featureType } = getSelectedFeature(this.state.selectedFeatures);
    const selected = featureType === KML_FEATURE_TYPE.SLOW && key === getZoneKey(selectedFeature.getProperties());
    if (!kmlFeature.geometry.coordinates) {
      return <></>;
    }
    return (
      <feature.PolygonReact
        coordinates={kmlFeature.geometry.coordinates}
        fillOptions={{ color: BROWN.concat([0.3]) }}
        strokeOptions={{ color: BLACK, width: selected ? 2 : 1 }}
        properties={kmlFeature.properties}
        key={`${key}-${KML_FEATURE_TYPE.SLOW}`}
        zIndex={this.Z_INDEX_ZONE}
        hideAtZoom={14}
      />
    );
  }

  /**
   * Generates a unsafe boundary
   * @param {object} kmlFeature GeoJSON object
   * @returns {PolygonReact}
   */
  genUnsafeFeature(kmlFeature: KMLFeature<DefaultKMLFeatureProperties>) {
    const key = getZoneKey(kmlFeature.properties);
    const { selectedFeature, featureType } = getSelectedFeature(this.state.selectedFeatures);
    const selected = featureType === KML_FEATURE_TYPE.UNSAFE && key === getZoneKey(selectedFeature.getProperties());
    if (!kmlFeature.geometry.coordinates) {
      return <></>;
    }
    return (
      <feature.PolygonReact
        coordinates={kmlFeature.geometry.coordinates}
        fillOptions={{ color: RED.concat(0.3) }}
        strokeOptions={{ color: BLACK, width: selected ? 2 : 1 }}
        properties={kmlFeature.properties}
        key={`${key}-${KML_FEATURE_TYPE.UNSAFE}`}
        zIndex={this.Z_INDEX_ZONE}
        hideAtZoom={14}
      />
    );
  }

  /**
   * Generates a generic boundary
   * @param {object} kmlFeature GeoJSON object
   * @returns {PolygonReact}
   */
  genGenericFeature(kmlFeature: KMLFeature<DefaultKMLFeatureProperties>) {
    const key = getZoneKey(kmlFeature.properties);
    const { selectedFeature, featureType } = getSelectedFeature(this.state.selectedFeatures);
    const selected =
      featureType === KML_FEATURE_TYPE.GENERIC_ZONE && key === getZoneKey(selectedFeature.getProperties());
    const uniqueKey = `${key}-${KML_FEATURE_TYPE.GENERIC_ZONE}`;
    const coords = kmlFeature.geometry.coordinates;
    if (!coords) {
      return <></>;
    }
    return (
      <feature.PolygonReact
        coordinates={coords}
        fillOptions={{ color: GREEN.concat(0.3) }}
        strokeOptions={{ color: BLACK, width: selected ? 2 : 1 }}
        properties={kmlFeature.properties}
        key={uniqueKey}
        zIndex={this.Z_INDEX_ZONE}
        hideAtZoom={14}
      />
    );
  }

  removeFeatureSelection() {
    this.state.selectedFeatures.dispose();
    this.updateSelectFeatures(new Collection([]));
  }

  updateSelectFeatures(selectedFeatures: Collection<Feature<SimpleGeometry>>) {
    // TODO should pass down actual job type here instead of inferring from props boolean
    const missionType: JobType = this.props.zoneMission ? 'Zone' : 'Grid';
    const nonDraggableFeatures = [
      ...this.NON_DRAGGABLE_FEATURES_BY_MAP[this.props.type],
      ...this.NON_DRAGGABLE_FEATURES_BY_JOB[missionType],
    ];
    const draggable = selectedFeatures
      .getArray()
      .filter((feature) => !nonDraggableFeatures.includes(getFeatureType(feature.getProperties())!));
    this.state.draggableFeatures.dispose();
    const modifiableFeatures = new Collection(draggable);

    this.setState({ draggableFeatures: modifiableFeatures, selectedFeatures });
  }

  isFeatureSimpleGeometry(feature: FeatureLike): feature is Feature<SimpleGeometry> {
    const geometry = feature.getGeometry();
    return !!geometry && 'getCoordinates' in geometry;
  }
  /**
   * Handle for selecting a feature, determines if a feature can be deleted
   * @param {*} event
   */
  handleSelectFeature(feature: FeatureLike) {
    //console.log(`KmlLayer handleSelectFeature ${JSON.stringify(feature)}`);
    clearTimeout(this.handleNothingTimeout);
    if (!this.isFeatureSimpleGeometry(feature)) return;
    //const featureType = getFeatureType(feature.getProperties());
    //const featureKey = getKey(feature.getProperties(), featureType);
    //console.log(`KmlLayer handleSelectFeature type=${featureType}, key=${featureKey}`);//: ${JSON.stringify(feature)}`);

    this.pendingSelectFeatures.push(feature);
    clearTimeout(this.nextHandleId);
    this.nextHandleId = setTimeout(() => this.handleSelectFeatures(), 0);

    return false;
  }

  deselectAll = () => {
    this.state.selectedFeatures.dispose();
    this.updateSelectFeatures(new Collection([]));
    this.props.addDeleteCandidate(null);
    this.props.addWaypointCandidate(null);
    this.props.addSkipCandidate(null);
    this.props.addMoveCandidate(null);
    this.pendingSelectFeatures = [];
  };

  handleSelectFeatures() {
    this.pendingSelectFeatures.sort(
      (feature1, feature2) =>
        this.SELECTION_PRIORITIES[getFeatureType(feature2.getProperties())!] -
        this.SELECTION_PRIORITIES[getFeatureType(feature1.getProperties())!],
    );
    //console.log(`pendingSelectFeatures length=${this.pendingSelectFeatures.length}`)
    const priorityFeature = this.pendingSelectFeatures[0];
    const priorityFeatureType = getFeatureType(priorityFeature.getProperties());
    const priorityFeatureKey = priorityFeatureType
      ? getKey(priorityFeature.getProperties(), priorityFeatureType)
      : undefined;

    if (!priorityFeatureType) {
      this.deselectAll();
      return;
    }

    if (!this.SELECTABLE_FEATURES.includes(priorityFeatureType)) {
      this.deselectAll();
      return;
    }

    const currentlySelectedFeatures = this.state.selectedFeatures?.getArray() || [];
    const hasCurrentlySelectedFeatures = currentlySelectedFeatures.length > 0;

    const currentFeatureProperties = currentlySelectedFeatures[0] && currentlySelectedFeatures[0].getProperties();
    const currentFeatureType = !!currentFeatureProperties && getFeatureType(currentFeatureProperties);
    const hasCurrentFeatureType = currentFeatureType !== undefined;

    if (
      hasCurrentlySelectedFeatures &&
      hasCurrentFeatureType &&
      priorityFeatureKey === getKey(currentlySelectedFeatures[0].getProperties(), currentFeatureType)
    ) {
      this.deselectTimer = setTimeout(() => {
        this.deselectAll();
      }, 300);
      this.pendingSelectFeatures = [];

      return;
    }

    this.updateSelectFeatures(new Collection([priorityFeature]));

    for (const [ACTIONABLE_FEATURES, addCandidate] of [
      [this.DELETEABLE_FEATURES_BY_JOB, this.props.addDeleteCandidate] as const,
      [this.SKIP_SETTABLE_FEATURES_BY_JOB, this.props.addSkipCandidate] as const,
      [this.WAYPOINT_SETTABLE_FEATURES_BY_JOB, this.props.addWaypointCandidate] as const,
      [this.POINT_MOVABLE_FEATURES_BY_JOB, this.props.addMoveCandidate] as const,
    ]) {
      if (ACTIONABLE_FEATURES[this.props.jobType].includes(priorityFeatureType)) {
        addCandidate({
          feature: priorityFeature,
          type: priorityFeatureType,
          removeFunc: this.removeFeatureSelection,
          coordinates: findMaxLat(priorityFeature.getGeometry()!.getCoordinates())!,
        });
      } else {
        addCandidate(null);
      }
    }

    this.pendingSelectFeatures = [];
  }

  /**
   * Handle feature modification based on type
   * @param {*} event
   */
  // ((event: T | Event) => void) | void
  handleKmlModify = async (event: ModifyEvent) => {
    clearTimeout(this.deselectTimer);
    this.deselectTimer = undefined;
    const features = event.features.getArray();
    if (features.length) {
      const properties = features[0].getProperties();
      const type = getFeatureType(properties);
      const coordinates = properties.geometry.getCoordinates();
      switch (type) {
        case KML_FEATURE_TYPE.PULLIN:
          await this.props.updatePullin(coordinates);
          break;
        case KML_FEATURE_TYPE.CENTROID:
          await this.props.updatePoints(coordinates, properties.name, { centroid: true });
          break;
        case KML_FEATURE_TYPE.CORE_POINT:
          await this.props.updatePoints(coordinates, properties.name);
          break;
        case KML_FEATURE_TYPE.PATH:
          await this.props.updatePath(coordinates);
          break;
        case KML_FEATURE_TYPE.FIELD:
        case KML_FEATURE_TYPE.GENERIC_ZONE:
        case KML_FEATURE_TYPE.COLLECTED_FIELD:
        case KML_FEATURE_TYPE.UNSAFE:
        case KML_FEATURE_TYPE.SLOW:
          await this.props.updateBoundary(coordinates, properties.name.toLowerCase(), properties.zone_id);
          break;
        default:
      }
    }
  };

  handleNothingClicked() {
    this.handleNothingTimeout = setTimeout(() => {
      this.props.addDeleteCandidate(null);
      this.props.addWaypointCandidate(null);
      this.props.addSkipCandidate(null);
      this.props.addMoveCandidate(null);
      this.state.selectedFeatures.dispose();
      this.updateSelectFeatures(new Collection([]));
    }, 300); // at one point, this was changed to 100ms and the "double click to delete" feature stopped working
    // this is likely timed with the deselectTimer and is really a race condition waiting to happen, but for now
    // we can change this and just move on... these two constants should probably at least be the same value
    // but given the current weird beavior, we will leave it as is for now
  }

  render() {
    const isSamplingMap = this.props.type === MAP_TYPES.SAMPLING;
    // previous selective layer render choice based on number of features
    //const Layer = this.state.kmlFeatures.length > 2000 ? layer.VectorImage : layer.Vector;
    const Layer = isSamplingMap ? layer.VectorImage : layer.Vector;
    //const Layer = layer.Vector;
    return (
      // we are choosing to use a VectorImage here vs a Vector because it causes a noticable performance improvement in the app
      // when not using a Vector. A Vector layer allegedly redraws far more frequently, and the data on this layer is for all
      // intents and purposes static-ish. The only time it changes is when a user modifies a feature, which is not a frequent
      // operation. Potentially an upgrade to the underlying openlayers library in react-openlayers could resolve the performance
      // issue, but for now we will use a VectorImage layer at all times
      // @ts-ignore
      <Layer
        key={'kmlLayer'}
        renderOrder={null}
        zIndex={0}
        fadeInOptions={{ step: 0.02, interval: 25, maxOpacity: 1, startOpacity: 0 }}
      >
        {/* 
                // @ts-ignore */}
        <source.VectorSourceReact>
          {this.state.kmlFeatures}
          <React.Fragment>
            {!this.props.selectInteractionDisabled && (
              <interaction.SelectReact
                key={'handleSelectFeature'}
                style={null}
                // @ts-ignore no matter how many times I've tried, I can't get these types right
                filter={this.handleSelectFeature}
                hitTolerance={isSamplingMap ? 40 : 20} // use 10, 20, 40. Add as user customization?
                onAnyClick={this.handleNothingClicked}
              />
            )}
            {!this.props.modifyInteractionDisabled && this.state.selectedFeatures.getArray().length > 0 && (
              // @ts-ignore
              <interaction.ModifyReact
                key={'handleKmlModify'}
                onModifyend={this.handleKmlModify}
                pixelTolerance={isSamplingMap ? 20 : 10}
                features={this.state.draggableFeatures}
                deleteCondition={doubleClick}
                insertVertexCondition={false}
              />
            )}
          </React.Fragment>
        </source.VectorSourceReact>
      </Layer>
    );
  }
}
