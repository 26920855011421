import { Mission, Waypoint } from '../db';
import { SamplingSpec } from '../db';
import { SoilCore } from '../db';
import { Sample } from '../db';
import { flatten, orderBy } from 'lodash';
import LineString from 'ol/geom/LineString';
import { CoordinatePoint } from '../types/types';
import { ft_to_m, CORE_STOP_MAX_DISTANCE_FT } from '../constants';
import { fromLatLon } from 'utm';
import { add_stop_core_waypoint, create_new_core, getAllCloseCores } from '../db_ops/mission_ops';

export class PathZoneGenerator {
  private mission: Mission;
  private spec: SamplingSpec;
  private waypointIndex: number = 1;
  private coreIndex: number = 1;
  private quotient: number = 0;
  private remainder: number = 0;

  constructor(mission: Mission, spec: SamplingSpec) {
    this.mission = mission;
    this.spec = spec;
  }

  public async generate(): Promise<void> {
    const samples = this.getSortedSamples();

    await this.deleteCloseCores(samples);
    this.deleteAllWaypoints();

    for (const sample of samples) {
      await this.processSample(sample);
    }
  }

  private getSortedSamples(): Sample[] {
    const samples = flatten(this.mission.getSampleSites().map((site) => site && site.getSamples())).filter(
      (sample) => !sample.skipped_or_deleted,
    );

    return orderBy(samples, ['order'], ['asc']);
  }

  private async deleteCloseCores(samples: Sample[]): Promise<void> {
    const soilCoresForDeletion = await getAllCloseCores(samples);
    for (const core of soilCoresForDeletion) {
      core.dispose();
    }
  }

  private deleteAllWaypoints(): void {
    const waypoints = this.mission.getWaypoints();
    for (const wp of waypoints) {
      wp.dispose();
    }
  }

  private async processSample(sample: Sample): Promise<void> {
    const orderedCores = orderBy(sample.getSoilCores(), ['core_number'], ['asc']);
    const coresNumber = orderedCores.length;

    // Calculate quotient and remainder for extra cores
    this.quotient = Math.max(0, Math.floor((this.spec.cores - coresNumber) / coresNumber));
    this.remainder = Math.max(0, (this.spec.cores - coresNumber) % coresNumber);
    this.coreIndex = 1;

    for (const core of orderedCores) {
      const previousPoint = this.choosePreviousPoint();
      await this.processCore(core, previousPoint, sample);
    }
  }

  private choosePreviousPoint(): CoordinatePoint {
    // If we can find a previous waypoint, use it.
    const previousWaypoint = this.mission.getWaypoints().find((wp: Waypoint) => wp.waypoint_number === this.waypointIndex - 1);
    if (previousWaypoint) {
      return previousWaypoint as CoordinatePoint;
    }

    const pullin: CoordinatePoint = {
      lat: this.mission.pullin_lat,
      lon: this.mission.pullin_lon,
      getCoordinates: () => [this.mission.pullin_lat, this.mission.pullin_lon],
    };

    // Otherwise - use pullin.
    return pullin;
  }

  private async processCore(core: SoilCore, prevPoint: CoordinatePoint, sample: Sample) {
    const lineString = new LineString([
      [core.lat, core.lon],
      [prevPoint.lat, prevPoint.lon],
    ]);

    const lineDistance = this.calcLineDistance(prevPoint, core);
    const extraCore = this.remainder > 0 ? 1 : 0;

    for (let j = this.quotient + extraCore; j > 0; j--) {
      this.addExtraCore(lineString, j, lineDistance, sample);
    }

    this.remainder--;

    // Add original core
    await this.addStopCoreWaypoint(core);
    this.waypointIndex++;
    this.coreIndex++;
  }

  private addExtraCore(lineString: LineString, j: number, lineDistance: number, sample: Sample) {
    const latLon = lineString.getCoordinateAt((j * ft_to_m(CORE_STOP_MAX_DISTANCE_FT)) / lineDistance);
    create_new_core(latLon, this.mission.instance_id, sample.instance_id, this.waypointIndex, this.coreIndex);

    this.waypointIndex++;
    this.coreIndex++;
  }

  private async addStopCoreWaypoint(core: SoilCore): Promise<void> {
    await add_stop_core_waypoint(core, this.mission.instance_id, this.waypointIndex, this.coreIndex);
  }

  private calcLineDistance(startPoint: CoordinatePoint, endPoint: CoordinatePoint): number {
    const startLoc = this.convertToUTM(startPoint.lat, startPoint.lon);
    const endLoc = this.convertToUTM(endPoint.lat, endPoint.lon);

    return Math.sqrt((endLoc.easting - startLoc.easting) ** 2 + (endLoc.northing - startLoc.northing) ** 2);
  }

  private convertToUTM(lat: number, lon: number): { easting: number; northing: number } {
    return fromLatLon(lat, lon);
  }
}
